import {useState} from 'react';
import axios from 'axios';
const Login = () =>{
    const [name, setName] = useState();
    const [pass, setPass] = useState();

   const handleSubmit=(e)=>{
        e.preventDefault();
        if(name != '' && pass !=''){
            axios.post('https://testapi.adypu-erp.com/create', {name,pass})
            .then(res => alert("form submit"))
            .then(err => console.log(err))
        }else{
           alert("required");
        }
    }
    return(
        <>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <input type="text" name="username" className='form-control' onChange={(e)=>{setName(e.target.value)}} placeholder='Enter Username'/>
                </div>
                <div className="mb-3">
                    <input type="password" name="username" className='form-control' onChange={(e)=>{setPass(e.target.value)}} placeholder='Enter Password'/>
                </div>
                <div className='mb-3'>
                    <button>Submit</button>
                </div>
            </form>
        </>
    )
}

export default Login;