import './App.css';
import Login from './Login';
function App() {

  return (
   <Login/>
  );
}

export default App;
